<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">LINE連携</h1>
        <banner-hint>
          公式LINEアカウントとシステムを連携することで ともだち登録しているお客様に新人情報や割引・クーポン等を一斉配信できるようになります。<br />
          メールマガジンと比べてLINEメッセージは到達率＆既読率が非常に高いため従来のメルマガに代わる来店促進用の強力なツールです。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        class="pt-5 mb-5"
      >
        <v-card
          class="px-10 py-7"
          elevation="1"
        >
          <icon-info icon="alert" class="mb-10" :square="true">
            更新できない情報に関してはシステム運営側にて変更します。必要の際はサポートまでご連絡ください。
          </icon-info>

          <v-form ref="form">
            <v-row>
              <!-- アカウント -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="lineIntegration.official_account_id"
                  label="公式アカウントID"
                  placeholder="例：@453ltfka"
                  counter="30"
                  required
                  :rules="[valiRules.required, valiRules.max30]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="lineIntegration.official_account_url"
                  label="公式アカウントURL"
                  placeholder="例：https://lin.ee/23GOEqmeT"
                  hint="公式アカウントアプリのホーム画面 →「友だちを増やす」メニューから取得できます"
                  required
                  :rules="[valiRules.required, valiRules.max100]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- アクセストークン -->
              <v-col cols="12">
                <v-text-field
                  v-model.trim="lineIntegration.access_token"
                  :filled="!!lineIntegration.shop_id"
                  :readonly="!!lineIntegration.shop_id"
                  label="LINE連携用アクセストークン"
                  hint="公式アカウント用LINEデベロッパーアカウント内のメッセージングAPI設定から取得できます"
                  required
                  :rules="[valiRules.required, valiRules.max300]"
                  @click="unableToEdit(!!lineIntegration.shop_id)"
                ></v-text-field>
              </v-col>
              <!-- ウェブフック -->
              <!-- <v-col cols="12">
                <v-text-field
                  v-model.trim="lineIntegration.webhook_url"
                  filled readonly
                  label="LINEボット用ウェブフック"
                  hint="LINEボット機能を使用しない場合は必要ありません"
                  :rules="[valiRules.max200]"
                  @click="unableToEdit"
                ></v-text-field>
              </v-col> -->
            </v-row>

            <v-row>
              <!-- チャネル情報 -->
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="lineIntegration.channel_id"
                  :filled="!!lineIntegration.shop_id"
                  :readonly="!!lineIntegration.shop_id"
                  label="チャネルID"
                  hint="公式アカウント用LINEデベロッパーアカウント内の基本設定から取得できます"
                  required
                  :rules="[valiRules.required, valiRules.max30]"
                  @click="unableToEdit(!!lineIntegration.shop_id)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model.trim="lineIntegration.channel_secret"
                  :filled="!!lineIntegration.shop_id"
                  :readonly="!!lineIntegration.shop_id"
                  label="チャネルシークレット"
                  hint="公式アカウント用LINEデベロッパーアカウント内の基本設定から取得できます"
                  required
                  :rules="[valiRules.required, valiRules.max50]"
                  @click="unableToEdit(!!lineIntegration.shop_id)"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- 通知受信用LINE -->
            <!-- <v-row class="mb-5">
              <v-col cols="12" sm="8">
                <icon-info icon="information-variant" class="mb-3 ml-n3">
                  ネット予約・LINE予約機能で予約が入った際に通知が入るLINEグループです。
                </icon-info>
                <v-text-field
                  v-model.trim="lineIntegration.notification_group_id"
                  label="通知受信用のLINEグループID"
                  hide-details
                  :rules="[valiRules.max50]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <icon-info icon="lightbulb-on" :square="true">
                  公式アカウントをグループ招待してトーク画面内でボタン↓を押してください
                </icon-info>
                <v-btn
                  depressed
                  color="primary"
                  @click="getLineGroupId()"
                >グループIDを確認</v-btn>
              </v-col>
            </v-row> -->
            <!-- スタッフLINE -->
            <!-- <v-row>
              <v-col cols="12">
                <icon-info icon="information-variant" class="mb-3 ml-n3">
                  LINEボットを有効にすると個別チャットが無効になるため、トーク画面リッチメニュー内にスタッフLINEへのリンクを貼ります。
                </icon-info>
                <v-text-field
                  v-model.trim="lineIntegration.staff_line_url"
                  label="個別対応用のスタッフLINEアカウントのURL"
                  hint="公式アカウントではく通常のLINEアカウントをご利用ください"
                  :rules="[valiRules.max100]"
                ></v-text-field>
              </v-col>
            </v-row> -->

            <!-- ボタン -->
            <v-row no-gutters justify="end" class="mt-3">
              <v-btn
                depressed
                color="primary"
                @click="submitted()"
              >更新</v-btn>
            </v-row>

          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- 確認モーダル -->
    <modal-confirm ref="modalConfirm">
      <div v-html="modalMessage"></div>
    </modal-confirm>

    <!-- オーバーレイメッセージ -->
    <overlay-message ref="overlayMessage">
      <div v-html="modalMessage"></div>
    </overlay-message>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import liff from '@line/liff'
import $literals from '@/literals.js'
import { LIFF_ID } from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import ModalConfirm from '@/components/_ModalConfirm.vue'
import OverlayMessage from '@/components/_OverlayMessage.vue'
import BannerHint from "@/components/_BannerHint.vue"
import IconInfo from "@/components/_IconInfo.vue"

export default {
  components: {
    'loader': Loader,
    'modal-confirm': ModalConfirm,
    'overlay-message': OverlayMessage,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      lineIntegration: {},
      modalMessage: '',
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  mounted() {
    if (this.shopData.system_plan_id < 2) {
      this.modalMessage = $literals.MESSAGE.availableForPaidPlan
      this.$refs.overlayMessage.open()
    } else {
      this.adminApi.setToken(this.serverToken)

      this.getData()
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
    }
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = 'データ取得中・・・'

      await Promise.all([
        this.adminApi.getReqWithAuth('line-integration/').then( data => {
          if (!data) return
          this.lineIntegration = data
        }),
      ])

      this.loading = false
    },

    unableToEdit(hasRegistered) {
      if (hasRegistered) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningShopInfoUnableToEdit, open: true}}
      }
    },

    getLineGroupId() {
      // liff初期化
      liff.init({ liffId: LIFF_ID }).then(() => {
        if (!liff.isInClient()) {
          this.snackbar = {...{color:'warning', message: 'こちらの操作はLINEのグループトーク内で行ってください', open: true}}
        } else {
          const context = liff.getContext()
          if (context.type === 'group') {
            this.modalMessage = '<p class="mb-4">以下を「通知受信用のLINEグループID」として管理画面で登録してください。</p>'+
                                '<p>' + context.groupId + '</p>'
            this.$refs.modalConfirm.open({})
          } else {
            alert('こちらの操作は公式アカウントを招待したグループトーク内で行ってください')
          }
        }
      })
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //更新
    //**************************************************
    submitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = 'データ更新中・・・'

      const apiPath = 'update/line-integration/'
      const formData = new FormData()
      formData.append('official_account_id', this.lineIntegration.official_account_id)
      formData.append('official_account_url', this.lineIntegration.official_account_url)
      formData.append('webhook_url', this.lineIntegration.webhook_url)
      formData.append('access_token', this.lineIntegration.access_token)
      formData.append('channel_id', this.lineIntegration.channel_id)
      formData.append('channel_secret', this.lineIntegration.channel_secret)
      formData.append('notification_group_id', this.lineIntegration.notification_group_id)
      formData.append('staff_line_url', this.lineIntegration.staff_line_url)

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
</style>
